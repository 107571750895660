.century {
  font-family: "Didact Gothic", sans-serif;
}
.corbel {
  font-family: "Open Sans", sans-serif;
}
.calibri {
  font-family: "Noto Sans", sans-serif;
}

// font style
.extra-bold {
  font-weight: 800;
}
.bold {
  font-weight: 700;
}
.light {
  font-weight: 300;
}
.italic {
  font-style: italic;
}

// Color classes
.laranja-padrao {
  color: var(--ion-color-warning-shade);
}
.marrom {
  color: var(--ion-color-secondary);
}
.cinza {
  color: var(--ion-color-step-150);
}
.laranja-avermelhado {
  // color: #ED4410;
  color: var(--ion-color-tertiary);
}
.branco {
  color: var(--ion-color-step-950, #f1f1f1);
}

// position classes
.center {
  text-align: center;
}
.modal {
  --height: calc(100% - 60px);
  margin-bottom: -30px;
  --border-radius: 10px 10px 0 0;
}
.modal-menu {
  --width: calc(100% - 60px);
  margin-left: -30px;
  --border-radius: 0 10px 10px 0;
}
.modal-menu-cardapio {
  --width: calc(100% - 60px);
  margin-right: -30px;
  --border-radius: 10px 0 0 10px;
}

//margins
.no-margin {
  margin-top: 0;
  margin-bottom: 0;
}
$margins: (5, 10, 15, 20, 25, 30, 35, 40, 50);
@each $margin in $margins {
  .mg-top-#{$margin}-percent {
    margin-top: $margin * 1%;
  }

  .mg-top-#{$margin} {
    margin-top: $margin * 1px;
  }
}

// .mg-top-5-percent {
//   margin-top: 5%;
// }
// .mg-top-10-percent {
//   margin-top: 10%;
// }
// .mg-top-15-percent {
//   margin-top: 15%;
// }
// .mg-top-20-percent {
//   margin-top: 20%;
// }
// .mg-top-25-percent {
//   margin-top: 25%;
// }
// .mg-top-30-percent {
//   margin-top: 30%;
// }
// .mg-top-35-percent {
//   margin-top: 35%;
// }
// .mg-top-40-percent {
//   margin-top: 40%;
// }

// .mg-top-5 {
//   margin-top: 5px;
// }
// .mg-top-10 {
//   margin-top: 10px;
// }
// .mg-top-15 {
//   margin-top: 15px;
// }
// .mg-top-20 {
//   margin-top: 20px;
// }
// .mg-top-25 {
//   margin-top: 25px;
// }
// .mg-top-30 {
//   margin-top: 30px;
// }
// .mg-top-40 {
//   margin-top: 40px;
// }
//paddings
.no-pad {
  padding: 0;
}

.pad-25 {
  padding: 25px 25px 0px 25px;
}
.pad-10 {
  padding: 10px 10px 0px 10px;
}

.pad-5 {
  padding: 5px 5px 5px 5px;
}
.pad-top-5-percent {
  padding-top: 5%;
}
.pad-top-10-percent {
  padding-top: 10%;
}
.pad-top-15-percent {
  padding-top: 15%;
}
.pad-top-20-percent {
  padding-top: 20%;
}
.pad-top-25-percent {
  padding-top: 25%;
}
.pad-top-30-percent {
  padding-top: 30%;
}
.pad-top-35-percent {
  padding-top: 35%;
}
.pad-top-40-percent {
  padding-top: 40%;
}

.pad-top-5 {
  padding-top: 5px;
}
.pad-top-10 {
  padding-top: 10px;
}
.pad-top-15 {
  padding-top: 15px;
}
.pad-top-20 {
  padding-top: 20px;
}
.pad-top-25 {
  padding-top: 25px;
}
.pad-top-30 {
  padding-top: 30px;
}
.pad-top-40 {
  padding-top: 35px;
}

// corrigi o problema do fill="outline" nao aparecer borda
ion-item[fill=outline], ion-item:host(.item-fill-outline), ion-item.item-fill-outline {
  $defaultColor: var(--ion-color-primary);
  $defaultColorContrast: var(--ion-color-primary-contrast-rgb);

  --border-width: 2px !important;
  --border-color: var(--ion-color-base, #{$defaultColor}) !important;
  --border-radius: 10px;
  --background: rgba(var(--ion-color-contrast-rgb, #{$defaultColorContrast}), .3) !important;
  --ion-item-background: var(--background);

  &.item-label-floating {
    --background: rgb(var(--ion-color-contrast-rgb, #{$defaultColorContrast})) !important;
  }

  &::part(native) {
    border-radius: var(--border-radius);
  }

  ion-input {
    --border-radius: 8px;
    > input {
     color: var(--color);
   }
  }

  &:not(.item-label) > ion-input {
    --padding-start: 16px;
  }

  &:not(.item-has-value):not(.item-has-focus) {
    > ion-label:first-child {
      transform: translate(0, 16px);
    }
  }

}
