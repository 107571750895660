/*
 * App Global CSS
 * ----------------------------------------------------------------------------
*/
// import fonts
@import url("https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Noto+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Open+Sans:ital,wght@0,300;0,400;0,700;0,800;1,600&display=swap");

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

// migrate ion-slide swiper
@import "~swiper/swiper";
@import "~@ionic/angular/css/ionic-swiper";

// LOUCURA
ion-card {
  --background: #f3f3f3;
  --ion-background-color: var(--background);
  --ion-item-background: var(--ion-background-color);
}

ion-button {
  outline: none !important;
}
