// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming

/** Ionic CSS Variables */
:root {
  /** primary **/
  --ion-color-primary: #e5690b;
  --ion-color-primary-rgb: 206, 97, 13;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: rgb(206, 97, 13);
  --ion-color-primary-tint: rgb(236, 124, 38);

  /** secondary **/
  --ion-color-secondary: #502407;
  --ion-color-secondary-rgb: 80, 36, 7;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #3f1d06;
  --ion-color-secondary-tint: #68300b;

  /** tertiary **/
  --ion-color-tertiary: #ed4410;
  --ion-color-tertiary-rgb: 202, 58, 14;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: rgb(202, 58, 14);
  --ion-color-tertiary-tint: rgb(248, 73, 19);

  /** success **/
  --ion-color-success: #16c45c;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #199e4e;
  --ion-color-success-tint: #36dd79;

  /** warning **/
  --ion-color-warning: #efcb1b;
  --ion-color-warning-rgb: 239, 203, 27;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0c539;
  --ion-color-warning-tint: #ebcc33;

  /** danger **/
  --ion-color-danger: #e02d2d;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #bd2323;
  --ion-color-danger-tint: #ee3030;

  /** dark **/
  --ion-color-dark: #262618;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1f1f13;
  --ion-color-dark-tint: #353521;

  /** medium **/
  --ion-color-medium: #f8f8f8;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #f1f1f1;
  --ion-color-medium-tint: #fafafa;

  /** light **/
  --ion-color-light: rgb(240, 238, 217);
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: rgb(214, 212, 177);
  --ion-color-light-tint: rgb(240, 237, 193);

  /** gray*/
  --ion-color-gray: #8b8b8b;
  --ion-color-gray-rgb: 255, 196, 9;
  --ion-color-gray-contrast: #000000;
  --ion-color-gray-contrast-rgb: 0, 0, 0;
  --ion-color-gray-shade: #6e6e6e;
  --ion-color-gray-tint: #a0a0a0;

  .ion-color-gray {
    --ion-color-base: var(--ion-color-gray);
    --ion-color-base-rgb: var(--ion-color-gray-rgb);
    --ion-color-contrast: var(--ion-color-gray-contrast);
    --ion-color-contrast-rgb: var(--ion-color-gray-contrast-rgb);
    --ion-color-shade: var(--ion-color-gray-shade);
    --ion-color-tint: var(--ion-color-gray-tint);
  }

  /*
   * iOS Light Theme
   * -------------------------------------------
   */
  .ios body {
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

   .md body {
    --ion-color-step-50: #1e1e1e;
    --ion-color-step-100: #2a2a2a;
    --ion-color-step-150: #363636;
    --ion-color-step-200: #414141;
    --ion-color-step-250: #4d4d4d;
    --ion-color-step-300: #595959;
    --ion-color-step-350: #656565;
    --ion-color-step-400: #717171;
    --ion-color-step-450: #7d7d7d;
    --ion-color-step-500: #898989;
    --ion-color-step-550: #949494;
    --ion-color-step-600: #a0a0a0;
    --ion-color-step-650: #acacac;
    --ion-color-step-700: #b8b8b8;
    --ion-color-step-750: #c4c4c4;
    --ion-color-step-800: #d0d0d0;
    --ion-color-step-850: #dbdbdb;
    --ion-color-step-900: #e7e7e7;
    --ion-color-step-950: #f3f3f3;
  }



  // --color: var(--ion-color-step-950, #fff);


  --ion-text-color: #404040;
  --ion-text-color-rgb: 64, 64, 64;

  --ion-background-color: #f2f2f2;
  --ion-background-color-rgb: 242, 242, 242;

  --ion-item-background: var(--ion-background-color);
  --ion-toolbar-background: var(--ion-background-color);


}

// FIXME: disable darkmode
// @media (prefers-color-scheme: dark) {
//   /*
//    * Dark Colors
//    * -------------------------------------------
//    */

//   body {
//     --color: var(--ion-color-step-950);

//     --background: #141417;
//     --ion-background-color: var(--background);
//     --ion-item-background: var(--ion-background-color);
//     --ion-toolbar-background: var(--background);

//     /** primary **/
//     --ion-color-primary: #ddc422;
//     --ion-color-primary-rgb: 221, 196, 34;
//     --ion-color-primary-contrast: #000000;
//     --ion-color-primary-contrast-rgb: 0, 0, 0;
//     --ion-color-primary-shade: #c2ac1e;
//     --ion-color-primary-tint: #e0ca38;

//     /** secondary **/
//     --ion-color-secondary: #512e17;
//     --ion-color-secondary-rgb: 81, 46, 23;
//     --ion-color-secondary-contrast: #ffffff;
//     --ion-color-secondary-contrast-rgb: 255, 255, 255;
//     --ion-color-secondary-shade: #472814;
//     --ion-color-secondary-tint: #62432e;

//     /** tertiary **/
//     --ion-color-tertiary: #e5690b;
//     --ion-color-tertiary-rgb: 229, 105, 11;
//     --ion-color-tertiary-contrast: #000000;
//     --ion-color-tertiary-contrast-rgb: 0, 0, 0;
//     --ion-color-tertiary-shade: #ca5c0a;
//     --ion-color-tertiary-tint: #e87823;

//     /** success **/
//     --ion-color-success: #0AC693;
//     --ion-color-success-rgb: 10,198,147;
//     --ion-color-success-contrast: #000000;
//     --ion-color-success-contrast-rgb: 0,0,0;
//     --ion-color-success-shade: #09ae81;
//     --ion-color-success-tint: #23cc9e;

//     /** warning **/
//     --ion-color-warning: #F7DC6B;
//     --ion-color-warning-rgb: 247,220,107;
//     --ion-color-warning-contrast: #000000;
//     --ion-color-warning-contrast-rgb: 0,0,0;
//     --ion-color-warning-shade: #d9c25e;
//     --ion-color-warning-tint: #f8e07a;

//     /** danger **/
//     --ion-color-danger: #e02d2d;
//     --ion-color-danger-rgb: 224, 45, 45;
//     --ion-color-danger-contrast: #ffffff;
//     --ion-color-danger-contrast-rgb: 255, 255, 255;
//     --ion-color-danger-shade: #c52828;
//     --ion-color-danger-tint: #e34242;

//     /** dark **/
//     --ion-color-dark: #2d333b;
//     --ion-color-dark-rgb: 45, 51, 59;
//     --ion-color-dark-contrast: #ffffff;
//     --ion-color-dark-contrast-rgb: 255, 255, 255;
//     --ion-color-dark-shade: #282d34;
//     --ion-color-dark-tint: #42474f;

//     /** medium **/
//     --ion-color-medium: #EDECEC;
//     --ion-color-medium-rgb: 237,236,236;
//     --ion-color-medium-contrast: #000000;
//     --ion-color-medium-contrast-rgb: 0,0,0;
//     --ion-color-medium-shade: #d1d0d0;
//     --ion-color-medium-tint: #efeeee;

//     /** light **/
//     --ion-color-light: #f6f7f8;
//     --ion-color-light-rgb: 246,247,248;
//     --ion-color-light-contrast: #000000;
//     --ion-color-light-contrast-rgb: 0,0,0;
//     --ion-color-light-shade: #d8d9da;
//     --ion-color-light-tint: #f7f8f9;
//   }

//   /*
//    * iOS Dark Theme
//    * -------------------------------------------
//    */

//   .ios body {
//     --background: #141417;
//     --ion-background-color: var(--background);
//     --ion-background-color-rgb: 0, 0, 0;
//     --ion-toolbar-background: var(--background);

//     --ion-text-color: var(--ion-color-step-950, #fff); // #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #0d0d0d;
//     --ion-color-step-100: #1a1a1a;
//     --ion-color-step-150: #262626;
//     --ion-color-step-200: #333333;
//     --ion-color-step-250: #404040;
//     --ion-color-step-300: #4d4d4d;
//     --ion-color-step-350: #595959;
//     --ion-color-step-400: #666666;
//     --ion-color-step-450: #737373;
//     --ion-color-step-500: #808080;
//     --ion-color-step-550: #8c8c8c;
//     --ion-color-step-600: #999999;
//     --ion-color-step-650: #a6a6a6;
//     --ion-color-step-700: #b3b3b3;
//     --ion-color-step-750: #bfbfbf;
//     --ion-color-step-800: #cccccc;
//     --ion-color-step-850: #d9d9d9;
//     --ion-color-step-900: #e6e6e6;
//     --ion-color-step-950: #f2f2f2;

//     // --ion-toolbar-background: #0d0d0d;
//     // --ion-item-background: #000000;
//   }

//   /*
//    * Material Design Dark Theme
//    * -------------------------------------------
//    */

//   .md body {
//     --background: #141417;
//     --ion-background-color: var(--background);
//     --ion-item-background: var(--ion-background-color);
//     --ion-toolbar-background: var(--background);

//     --ion-background-color-rgb: 18, 18, 18;

//     --ion-text-color: var(--ion-color-step-950, #fff); // #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     // --ion-border-color: #222222;

//     --ion-color-step-50: #1e1e1e;
//     --ion-color-step-100: #2a2a2a;
//     --ion-color-step-150: #363636;
//     --ion-color-step-200: #414141;
//     --ion-color-step-250: #4d4d4d;
//     --ion-color-step-300: #595959;
//     --ion-color-step-350: #656565;
//     --ion-color-step-400: #717171;
//     --ion-color-step-450: #7d7d7d;
//     --ion-color-step-500: #898989;
//     --ion-color-step-550: #949494;
//     --ion-color-step-600: #a0a0a0;
//     --ion-color-step-650: #acacac;
//     --ion-color-step-700: #b8b8b8;
//     --ion-color-step-750: #c4c4c4;
//     --ion-color-step-800: #d0d0d0;
//     --ion-color-step-850: #dbdbdb;
//     --ion-color-step-900: #e7e7e7;
//     --ion-color-step-950: #f3f3f3;
//   }

//   ion-title.title-large {
//     --color: var(--ion-color-step-950, #fff);
//   }
// }
